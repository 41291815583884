<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item v-for="item in levelList" :to="item.path" :key="item.path">{{item.meta.title}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>

<script>
export default {
  name:'breadcrumb',
  data(){
    return{
      levelList:[]
    }
  },
  mounted(){
    this.getBreadcrumb();
  },
  methods:{
    getBreadcrumb() {
      let matched = this.$route.matched.filter(item => item.meta)
      matched = [{ path: '/index', meta: { title: '面板' }}].concat(matched);
      this.levelList = matched.filter(item => item.meta && item.meta.title);
    },
  }
};
</script>

<style lang="scss" scoped>
</style>