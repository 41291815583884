<template>
  <div id="industry-second-level-head">
    <bread-crumb />
    <div>
      <el-row type="flex" align="middle">
        <el-col :span="14">
          <el-row class="industry-second-level-title">
            <el-col :span="4" class="industry-second-level-title-text">
              {{ title }}
            </el-col>
            <el-col :span="4" class="active-color industry-second-level-info">
              <i class="el-icon-warning-outline"></i>
              <span> {{ title }}简介</span>
            </el-col>
            <el-col :span="16" class="active-color industry-second-level-info">
              <i class="el-icon-warning-outline"></i>
              <span> {{ title }}相关文档</span>
            </el-col>
          </el-row>
          <div class="industry-second-level-desc">
            {{ intro }}
          </div>
          <el-button
            class="el-button-back"
            size="small"
            type="primary"
            plain
            @click="$router.back()"
            >返回</el-button
          >
        </el-col>
        <el-col :span="10" class="right">
          <img
            v-if="enName"
            :src="require('../../../assets/img/tip_' + enName + '.png')"
          />
          <!-- <img src="../../../assets/img/tip_CRM.png" alt="" /> -->
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import BreadCrumb from "@/components/bread_crumb.vue";
export default {
  data() {
    return {};
  },
  props: ["intro", "title", "enName"],
  components: {
    BreadCrumb,
  },
  watch: {
    title: {
      immediate: true,
      handler(val) {
        this.$route.meta.title = val + "列表";
      },
    },
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
#industry-second-level-head {
  background: #fff;
  padding: 15px 32px;
  border-top: 1px solid rgba(0, 21, 41, 0.12);
  border-bottom: 1px solid rgba(0, 21, 41, 0.12);
  .industry-second-level-desc {
    font-size: 14px;
    color: #374567;
    margin: 18px 0 14px;
  }
  .industry-second-level-title {
    font-size: 20px;
    color: #374567;
    height: 28px;
    line-height: 28px;
    margin: 16px 0;
    .industry-second-level-title-text {
      font-weight: bold;
    }
  }
  .industry-second-level-info {
    font-size: 14px;
  }
  .el-button {
    height: 32px;
    width: 74px;
  }
  .right {
    img {
      width: 210px;
    }
  }
  .el-button-back.el-button--primary.is-plain {
    background: #fff;
  }
  .el-button--primary.is-plain:focus,
  .el-button--primary.is-plain:hover {
    background: #409eff;
    border-color: #409eff;
    color: #fff;
  }
  /deep/.is-link {
    font-weight: normal;
    color: rgba(55, 69, 103, 0.45);
  }
  /deep/.el-breadcrumb__inner {
    color: rgba(55, 69, 103, 0.65);
  }
}
</style>