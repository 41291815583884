<template>
  <div id="industry-template-group">
    <el-row type="flex">
      <el-col v-for="item in subList" :key="item.id">
        <!-- <a :href="item.link"> -->
        <div @click="linkEvent(item)">
          <industry-template-item :item="item" :name="name" />
        </div>
        <!-- </a> -->
      </el-col>
    </el-row>
  </div>
</template>

<script>
import IndustryTemplateItem from "./template_item.vue";
import {setCookie } from "@/util/cookie";
export default {
  props: ["subList", "name"],
  components: {
    "industry-template-item": IndustryTemplateItem,
  },
  methods: {
    linkEvent(item) {
      const userInfo = JSON.parse(localStorage.getItem('userInfo'));
      const token = localStorage.getItem('token');
      setCookie('userInfo',userInfo,30);
      setCookie('token',token,30);
      window.location = item.link;
    },
  },
};
</script>

<style lang="scss" scoped>
#industry-template-group {
  padding: 24px 24px 0;

  .el-row {
    margin: 0 auto;
    width: 90%;
    .el-col {
      width: 378px;
      margin-right: 44px;
      justify-self: flex-start;
      align-self: flex-start;
    }
    .el-col:nth-of-type(3n) {
      margin-right: 0;
    }
    flex-wrap: wrap;
  }
}
</style>