<template>
    <div>
        <industry-head :intro='data.intro' :enName='data.enName' :title='data.name'></industry-head>
        <industry-template-group :subList='data.subList' :name='data.name'></industry-template-group>
    </div>
</template>

<script>
    import IndustryHead from './components/head.vue'
    import IndustryTemplateGroup from './components/template_group'
    import { moduleDetail } from "@/api/module";
    export default {
        data(){
            return {
                id:'',
                data:{}
            }
        },
        components:{
            'industry-head':IndustryHead,
            'industry-template-group':IndustryTemplateGroup
        },
        mounted(){
            this.id = this.$route.query.id;
            moduleDetail(this.id).then(res=>{
                this.data = {...res.data};
            })
        }
    }
</script>

<style lang="scss" scoped>

</style>