<template>
  <div class="industry-second-template-item" :style="{backgroundImage:item.bg_pic?'url('+item.bg_pic+')':'url('+require('../../../assets/img/detail_default_bg.png')+')'}">
    <el-row>
      <el-col :span="6">
        <div class="industry-second-template-name" :style="{'backgroundColor':randomHexColor()}">
          {{ item.customer.name }}
        </div>
      </el-col>
      <el-col :span="18">
        <div class="industry-second-template-title">
          {{ name }}-{{ item.customer.name }}版
        </div>
        <div class="industry-second-template-desc">
          {{ item.desc }}
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["item", "name"],
  methods: {
    randomHexColor() {
      const colors = ['#5B7EFF','#FFBF00','#13C2C2','#1890FF','#8EC31F'];
      return colors[Math.floor(Math.random()*colors.length)]
    },
  },
};
</script>

<style lang="scss" scoped>
.industry-second-template-item {
  padding: 24px 58px 0 24px;
  font-size: 14px;
  width: 378px;
  height: 184px;
  overflow: hidden;
  box-sizing: border-box;
  background: #fff;
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.09);
  margin-bottom: 25px;
  background-size: 100% 100%;
  // color: $default-color;
  // @include max-line-2;
  .industry-second-template-name {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: #5b7eff;
    color: #fff;
    line-height: 48px;
    text-align: center;
  }
  .industry-second-template-title {
    font-size: 16px;
    color: #374567;
    margin-bottom: 13px;
  }
  .industry-second-template-desc {
    font-size: 14px;
    color: rgba(55, 69, 103, 0.65);
  }
}
</style>